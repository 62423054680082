html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: black;
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 20px;
}

.app-header {
  text-align: center;
}